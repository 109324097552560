import Link from "next/link";
import { useRouter } from "next/router";
import { FormEvent, MouseEventHandler, useEffect, useState } from "react";
import {CredentialResponse, GoogleLogin} from "@react-oauth/google";
import { getFacebookAuthToken, getGoogleAuthToken, loginApi } from "../apiNest/NestApiService";
import { Auth, AuthApiProps  } from "../apiNest/models/content/auth";
import UserManager from "../auth/userManager";
import Footer from "../components/footer";
import FormInput from "../components/formInput";
import Header from "../components/header";
import Img from "../components/image";
import ShowError from "../components/showError";
import { handleChange } from "../functions/handleInput";
import FacebookLogin, { ReactFacebookLoginInfo } from 'react-facebook-login';
import { AccountContent } from "../apiNest/models/content/account";
import MetaDescription from "../components/metaDescription";
import useWindowDimensions from "../functions/getWindowDimensions";

export default function Login() {
  const userManager = new UserManager();
  const [formLogin, setFormLogin] = useState({
    email: "",
    password: "",
  });
  const [errorLogin, setErrorLogin] = useState({
    isError: false,
    message: "",
  });
  const router = useRouter();
  const { em } = router.query;

  useEffect(() => {
    protectPage().then(()=>{})
    if (!router.isReady) return;
    em && setFormLogin({ email: em as string, password: "" })
  }, [router]);

  const responseGoogle = async (response: CredentialResponse) => {
    setErrorLogin({
      isError: false,
      message: ""
    });
    const data = await getGoogleAuthToken({
      id_token: response.credential
    }) as Auth;
    if ((data.statusCode === 500 || !data)) {
      setErrorLogin({
        isError: true,
        message: "เข้าสู่ระบบล้มเหลว กรุณาลองใหม่อีกครั้ง"
      });
      return
    }
    const user: AccountContent | null = await userManager.saveToken(data.access_token, data.refresh_token);
    router.query?.redirect ? router.replace(router.query?.redirect as string) : await router.replace(user?.createdType === 'Imported' && !user?.has_password ? '/communication-page' : "/library");
  }

  const responseFacebook = async (response: ReactFacebookLoginInfo) => {
    setErrorLogin({
      isError: false,
      message: ""
    });
    if (response.accessToken) {
      const data = await getFacebookAuthToken({
        access_token: response.accessToken as string,
        free_trial: router.query?.redirect === "/free-trial/"
      }) as Auth;
      if ((data.statusCode === 500 || !data)) {
        setErrorLogin({
          isError: true,
          message: "เข้าสู่ระบบล้มเหลว กรุณาลองใหม่อีกครั้ง"
        });
        return
      }
      const user: AccountContent | null = await userManager.saveToken(data.access_token, data.refresh_token);
      router.query?.redirect ? router.replace(router.query?.redirect as string) : await router.replace(user?.createdType === 'Imported' && !user?.has_password ? '/communication-page' : "/library");
    }
  }

  async function protectPage() {
    if (userManager.isLoggedIn()) {
      await router.replace("/library");
    }
  }

  async function loginRequest(event?: FormEvent) {
    event && event.preventDefault();
    setErrorLogin({
      isError: false,
      message: ""
    });
    const formData: AuthApiProps = {
      username: formLogin.email,
      password: formLogin.password,
      free_trial: router.query?.redirect === "/free-trial/"
    }
    let data = await loginApi(formData);
    if (!data) {
      setErrorLogin({
        isError: true,
        message: "เข้าสู่ระบบล้มเหลว กรุณาลองใหม่อีกครั้ง"
      });
      return
    }
    if (!data.message) {
      const user: AccountContent | null = await userManager.saveToken(data.access_token, data.refresh_token);
      router.query?.redirect ? router.replace(router.query?.redirect as string) : await router.replace(user?.createdType === 'Imported' && !user?.has_password ? '/communication-page' : "/library");
    } else {
      setErrorLogin({
        isError: true,
        message: "อีเมลหรือรหัสผ่านไม่ถูกต้องกรุณาลองใหม่หรือเข้าสู่ระบบด้วย Google/Facebook",
      })
    }
  }

  const GoogleLoginWidth = () => {
    const { width } = useWindowDimensions();
    return width < 440 ? `${width - 80}px` : "400px";
  };

  const checkWindow = () => {
    return typeof window !== 'undefined'
  }

  return (
    <div className="background-image login">
      <MetaDescription data={null} />
      <Header />
      <div className="sizer sizer-full">
        <div className="container">
          <div className="row align-items-center justify-content-center ">
            <div className="card-login text-center login-background">
              <div className="auth-content">
                <div className="f-s-50 m-b-10">
                  เข้าสู่ระบบ
                </div>
                <div className="column-center">
                  {checkWindow() && (
                    <GoogleLogin
                    width={GoogleLoginWidth()}
                    shape={'pill'}
                    onSuccess={responseGoogle}
                    onError={() => {
                      console.error('Google Login Failed');
                    }}
                  />
                  )}
                  <FacebookLogin
                    appId={
                      process.env
                        .NEXT_PUBLIC_NEXTAUTH_FACEBOOK_CLIENT_ID as string
                    }
                    callback={responseFacebook}
                    disableMobileRedirect={true}
                    cssClass="btn btn-icon btn-full m-b-10 m-x-0 background-color-facebook"
                    containerStyle={{ width: "100%", transition: "none" }}
                    icon={
                      <div className="icon-frame p-0">
                        <Img
                          src="/login/facebook-icon.png"
                          width={30}
                          height={30}
                          alt="Facebook"
                        />
                      </div>
                    }
                    textButton="เข้าสู่ระบบด้วย Facebook"
                  />
                </div>
                <hr />
                <div className="p-20">
                  <div className="form">
                    <form onSubmit={(e) => loginRequest(e)}>
                      <div className="wrap-text-fit">
                        {errorLogin.isError && (
                          <ShowError message={errorLogin.message} />
                        )}
                      </div>
                      <FormInput
                        id={"email"}
                        label="อีเมล"
                        type={"email"}
                        required={true}
                        placeholder={""}
                        noInputError={"โปรดระบุอีเมลของคุณ"}
                        value={formLogin.email}
                        onChange={(e) =>
                          handleChange(e, setFormLogin, formLogin)
                        }
                      />
                      <FormInput
                        id={"password"}
                        label="รหัสผ่าน"
                        type={"password"}
                        required={true}
                        tooShortError={"รหัสผ่าน (โปรดใช้อักขระอย่างน้อย 8 ตัว)"}
                        noInputError={"โปรดระบุรหัสผ่านของคุณ"}
                        emailError={'กรุณาระบุอีเมลให้ถูกต้อง'}
                        placeholder={""}
                        minLength={8}
                        onChange={(e) =>
                          handleChange(e, setFormLogin, formLogin)
                        }
                      />
                      <button
                        id="form-button"
                        className="btn btn-solid btn-full"
                        type="submit"
                      >
                        เข้าสู่ระบบ
                      </button>
                    </form>
                  </div>
                  <div className="login-bottom justify-content-center">
                    <span className="color-black">ไม่ได้เป็นสมาชิก? &nbsp;</span>
                    <Link href="/register" passHref={true}>
                      <a className="link-colorless">คลิกเพื่อสร้างบัญชีผู้ใช้งานใหม่</a>
                    </Link>
                  </div>
                  <div className="form-group p-t-15 p-x-0 p-n-5">
                    <label className="justify-content-center d-flex">
                      <Link href="/forgot-password" passHref={true}>
                        <a className="link-colorful">ลืมรหัสผ่าน?</a>
                      </Link>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
